import React from 'react'
import styled from 'styled-components'

import { widowFix } from 'utils/string'

import * as spacing from 'styles/spacing'
import { largerThan, smallerThan } from 'styles/media'

import Heading from 'components/Heading'

const Container = styled.div`
  display: grid;
  grid-gap: ${spacing.large};

  ${largerThan.xSmall`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${largerThan.small`
    grid-template-columns: repeat(3, 1fr);
  `};

  ${largerThan.medium`
    grid-gap: ${spacing.xLarge};
  `};

  ${largerThan.large`
    grid-gap: ${spacing.xxLarge};
  `};

  ${largerThan.xLarge`
    grid-gap: ${spacing.xxxLarge};
  `};
`

const Number = styled(Heading)`
  margin-bottom: 0;

  ${largerThan.small`
    margin-bottom: ${spacing.tiny};
  `};
`

const Label = styled.span`
  display: block;

  ${smallerThan.small`
    font-size: 0.9em;
    margin-top: ${spacing.scale(spacing.tiny, 0.5)};
  `};
`

const Stats = ({ stats }) => (
  <Container>
    {stats.map((stat, i) => (
      <div key={i}>
        <Number size={2} tag="div">
          {stat.number}
        </Number>
        <Label>{widowFix(stat.label)}</Label>
      </div>
    ))}
  </Container>
)

export default Stats
