import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { INFO_FOR_PROJECT_NAME } from 'utils/constants'

import CaseHeader from 'components/work/CaseHeader'
import ContentSection from 'components/work/ContentSection'
import WorkShowcase from 'components/work/WorkShowcase'
import Wrapper from 'components/Wrapper'
import Stats from 'components/Stats'
import MetaTags from 'components/MetaTags'
import Section from 'components/Section'
import Testimonial from 'components/Testimonial'
import Pill from 'components/Pill'
import PillGroup from 'components/Pill/PillGroup'
import Related from 'components/industries/Related'

const projectHandle = 'usewalter'

const Usewalter = () => (
  <>
    <MetaTags title="Development team augmentation for Usewalter | Custom software development in Montreal | Volume7" />

    <CaseHeader
      breadcrumbs={[
        {
          label: 'Work',
          link: '/work/',
        },
        {
          label: 'Usewalter',
        },
      ]}
      client="Usewalter"
      heading={INFO_FOR_PROJECT_NAME[projectHandle].heading}
      industry={INFO_FOR_PROJECT_NAME[projectHandle].industry}
      year={INFO_FOR_PROJECT_NAME[projectHandle].year}
      figure={INFO_FOR_PROJECT_NAME[projectHandle].figure}
    />

    <ContentSection
      title="The challenge"
      content={
        <>
          <p>
            Following an acquisition in late 2020, Montreal-based PropTech
            startup Usewalter was eager to quickly improve their property
            management product offering. In an effort to increase development
            velocity and expand into new markets, they sought out to find a
            full-service partner to augment their internal team.
          </p>
          <p>
            Usewalter chose us because of our expertise working with high-growth
            startups, as well as our deep technical know-how across the full
            stack. We were mandated with accelerating their development efforts,
            establishing a strong technical foundation on which their future
            in-house team could build upon, and redefining the entire product
            look and feel across all platforms.
          </p>
        </>
      }
    />

    <ContentSection
      title="Our solution"
      content={
        <>
          <p>
            We worked with key Usewalter stakeholders to identify high-impact
            features and improvements, and assigned a dedicated team to the
            project. Over the course of 6 months, we defined a new look and feel
            for the app, took the lead on development of these new key features,
            increased developer productivity through advanced tooling, and
            implemented an automated testing pipeline, all while bringing their
            newly hired developers up to speed.
          </p>

          <ul>
            <li>Comprehensive web platform redesign and code refactor</li>
            <li>
              Scalability and performance improvements (rate limiting, query
              cost calculation, centralized caching and worker node structure)
            </li>
            <li>
              Cross-platform real-time chat with rich functionality (read
              receipts, status indicator, contextual cards, timeline events)
            </li>
            <li>
              Task management system, overview dashboard, multi-class search,
              localization and internationalization
            </li>
            <li>
              Payment infrastructure to allow rent and condo fee processing
            </li>
            <li>Fully automated end-to-end testing pipeline</li>
            <li>
              Quick and modernized development, building, and deployment
              pipelines
            </li>
            <li>Complete mobile app design overhaul for iOS and Android</li>
          </ul>
          <PillGroup>
            <Pill label="Project management" link="/services/strategy/" />
            <Pill label="Analytics" link="/services/analytics/" />
            <Pill
              label="User experience (UX) design"
              link="/services/design/"
            />
            <Pill label="User interface (UI) design" link="/services/design/" />
            <Pill label="Web development" link="/services/development/" />
            <Pill label="Backend development" link="/services/development/" />
            <Pill label="Automated testing" link="/services/development/" />
          </PillGroup>
        </>
      }
    />

    <Section flushTop>
      <Wrapper>
        <WorkShowcase
          photos={[
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/usewalter/dashboard.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              ),
              large: true,
              caption: 'Dashboard for managing company admins',
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/usewalter/chat.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              ),
              large: true,
              caption:
                'Full-fledged chat system between managers and residents',
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/usewalter/resident-app.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              ),
              large: true,
              caption: 'Mobile app for condo residents',
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/usewalter/design-system.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              ),
              large: true,
              caption: 'Web design system',
            },
          ]}
        />
      </Wrapper>
    </Section>

    <ContentSection
      title="The results"
      content={
        <>
          <p>
            Following our engagement, Usewalter had grown to a technical team of
            ~15, now equipped with the necessary tools and resources to scale
            the platform. Key functionality had also been implemented and now
            considered core to their updated product offering, providing them
            with the extra firepower to enter new markets.
          </p>
          <ul>
            <li>Complete platform refactor in just 6 months</li>
            <li>
              Significant increase in development velocity with improved
              developer experience (DX)
            </li>
            <li>
              <strong>60%</strong> increase in in-app conversations between
              residents and managers
            </li>
            <li>
              Issues raised by residents resolved on average{' '}
              <strong>40%</strong> faster
            </li>
            <li>
              <strong>45%</strong> decrease in user reported bugs and errors
            </li>
          </ul>
        </>
      }
      extra={
        <Stats
          stats={[
            {
              number: '60%',
              label: 'Increase in in-app conversations',
            },
            {
              number: '40%',
              label: 'Faster issue resolution',
            },
            {
              number: '45%',
              label: 'Decrease in user reported bugs and errors',
            },
          ]}
        />
      }
    />

    <Section flushTop>
      <Wrapper size="narrow">
        <Testimonial
          name="Thierry Skoda"
          role="Co-Founder & CTO, Usewalter"
          quote="Volume7 brings a rare mix of business acumen and technical expertise to the table. They're my go-to software development agency."
          alignment="left"
        />
      </Wrapper>
    </Section>
    <Related projects={['aldo', 'vape']} hasBorder />
  </>
)

export default Usewalter
